<template>
  <v-app>
    <v-app-bar>
      <v-app-bar-title @click="navigateTo(Routes.home)">
        {{ $t('app.name') }}
      </v-app-bar-title>
      <v-btn
          v-if="shouldDisplayDashboardButton"
          :to="Routes.app.home"
          variant="elevated"
          color="primary"
          :text="$t('app.nav.dashboard')"
      />
      <v-btn
          v-if="shouldDisplayLoginButton"
          :to="Routes.login"
          variant="tonal"
          color="secondary"
          :text="$t('app.nav.login')"
          append-icon="mdi mdi-login"
      />
      <v-btn
          v-if="isLoggedIn && $route.path === Routes.app.signup.complete"
          :to="Routes.logout"
          variant="tonal"
          color="secondary"
          :text="$t('app.nav.logout')"
          append-icon="mdi mdi-logout"
      />
    </v-app-bar>
    <v-main class="mt-15 pt-8 mt-md-15 mx-5 mx-md-0">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-alert type="info" close-label="my-3" closable>
              <v-alert-title>
                NOTICE
              </v-alert-title>
              This is not a functional website at the moment.
            </v-alert>
          </v-col>
        </v-row>
        <slot/>
      </v-container>
    </v-main>
  </v-app>
</template>
<script setup lang="ts">
import {Routes} from "~/lib/navigation";
import {useAccountsStore} from "~/lib/accounts";
import {useRoute, storeToRefs} from "#imports";

const route = useRoute();
const accounts = useAccountsStore();
const {isLoggedIn} = storeToRefs(accounts);

const shouldDisplayDashboardButton = computed((): boolean => {
  return isLoggedIn.value && ![Routes.app.signup.complete].includes(route.path);
})
const shouldDisplayLoginButton = computed((): boolean => {
  return !isLoggedIn.value && ![Routes.login, Routes.signUp].includes(route.path)
});
</script>